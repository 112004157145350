import relatedSlider from './relatedPostsSlider'

const oneLineMobileSlider = (
    selector = '.line-slider',
    breakpoint = '78.125rem',
    options = {},
    query = 'max-width',
) => {
    const MEDIA_QUERY = `(${query}: ${breakpoint})`
    const mediaQueryList = window.matchMedia(MEDIA_QUERY)

    const applySlider = () => {
        relatedSlider(selector, options)
    }

    const destroySlider = () => {
        document.querySelectorAll(selector).forEach((el) => {
            if (el.swiper) {
                el.swiper.destroy(true, true)
                el.swiper = null
            }
        })
    }

    const handleChange = (event) => {
        if (event.matches) {
            applySlider()
        } else {
            destroySlider()
        }
    }

    // Set up the listener for media query changes
    mediaQueryList.addEventListener('change', handleChange)

    // Perform an initial check
    if (mediaQueryList.matches) {
        applySlider()
    } else {
        destroySlider()
    }
}

export default oneLineMobileSlider
